import { selectWidgetType } from 'app/imports/App.selectors'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { FiltersByDistrict } from '../FiltersByDistrict/FiltersByDistrict'
import { FiltersByLeague } from '../FiltersByLeague/FiltersByLeague'
import { FiltersByState } from '../FiltersByState/FiltersByState'

export function Filters() {
  const widgetType = useSelector(selectWidgetType)

  if (widgetType === WidgetTypes.State) {
    return <FiltersByState />
  }

  if (widgetType === WidgetTypes.StateLeague) {
    return <FiltersByLeague />
  }

  if (widgetType === WidgetTypes.District || widgetType === WidgetTypes.League) {
    return <FiltersByDistrict />
  }

  return null
}
