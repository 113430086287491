import { getSelectedLeague } from 'app/imports/App.selectors'
import { useSelector } from 'react-redux'

export function LayoutHeaderLeague() {
  const league = useSelector(getSelectedLeague)

  if (!league) {
    return null
  }

  return <>{league.name} Scoreboard</>
}
