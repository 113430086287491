import {
  getIsLeague,
  getSelectedDate,
  getSelectedDistrict,
  getSelectedLeague,
  getSelectedSchool,
  getSelectedSport,
  getSelectedState,
  selectWidgetType,
} from 'app/imports/App.selectors'
import { cszUrl } from 'features/Env'
import { UISpinner } from 'features/UI'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { Game } from '../../Games.model'
import { useGetGamesBySchoolQuery, useGetGamesByStateQuery } from '../../state/Games.rtk'
import { GameCard } from '../GameCard/GameCard'
import * as Styled from './GamesList.styled'

export function GamesList() {
  const widgetType = useSelector(selectWidgetType)
  const selectedState = useSelector(getSelectedState)
  const isLeague = useSelector(getIsLeague)
  const selectedLeague = useSelector(getSelectedLeague)
  const selectedDistrict = useSelector(getSelectedDistrict)
  const selectedSport = useSelector(getSelectedSport)
  const selectedSchool = useSelector(getSelectedSchool)
  const selectedDate = useSelector(getSelectedDate)

  const skipGameByStateRequest = useMemo(() => {
    if (widgetType === WidgetTypes.State || widgetType === WidgetTypes.StateLeague) {
      return selectedState === null
    }

    if (widgetType === WidgetTypes.League) {
      return selectedState === null || selectedLeague === null
    }

    if (widgetType === WidgetTypes.District) {
      return selectedState === null || selectedDistrict === null
    }

    return true
  }, [selectedDistrict, selectedLeague, selectedState, widgetType])

  const { data: games, isFetching } = useGetGamesByStateQuery(
    {
      stateId: selectedState?.id,
      districtId: selectedDistrict?.id,
      leagueId: selectedLeague?.id,
      sportId: selectedSport?.id,
      isLeague,
      date: selectedDate,
    },
    { skip: skipGameByStateRequest },
  )

  const { data: gamesBySchool, isFetching: isGamesBySchoolFetching } = useGetGamesBySchoolQuery(
    {
      stateId: selectedState?.id,
      districtId: selectedDistrict?.id,
      schoolId: selectedSchool?.id,
      date: selectedDate,
    },
    {
      skip:
        widgetType !== WidgetTypes.School || !selectedState || !selectedDistrict || !selectedSchool,
    },
  )

  const isLoading = useMemo(() => {
    if (widgetType === WidgetTypes.School) {
      return isGamesBySchoolFetching
    }

    return isFetching
  }, [isFetching, isGamesBySchoolFetching, widgetType])

  const gamesData = useMemo(() => {
    if (widgetType === WidgetTypes.School) {
      return gamesBySchool
    }

    return games
  }, [games, gamesBySchool, widgetType])

  const scoresUrl = useMemo(() => {
    const queryParams = [`selectedDate=${selectedDate}`]

    if (selectedState) {
      queryParams.push(`selectedStateId=${selectedState?.id}`)
    }

    if (selectedDistrict) {
      queryParams.push(`selectedCountyId=${selectedDistrict?.id}`)
    }

    if (selectedSport) {
      queryParams.push(`selectedSportId=${selectedSport?.id}`)
    }

    return `${cszUrl}/scores?${queryParams.join('&')}`
  }, [selectedDate, selectedDistrict, selectedSport, selectedState])

  if (isLoading || !gamesData) {
    return (
      <Styled.EmptyContainer>
        <UISpinner />
      </Styled.EmptyContainer>
    )
  }

  if (gamesData.games.length === 0) {
    return (
      <Styled.EmptyContainer>
        <Styled.NoGamesTitle>No games found</Styled.NoGamesTitle>
      </Styled.EmptyContainer>
    )
  }

  return (
    <>
      <Styled.GamesList>
        {gamesData.games.map((game: Game, i) => {
          return <GameCard key={`${game.id}_${i}`} game={game} />
        })}
      </Styled.GamesList>

      <Styled.ButtonContainer href={scoresUrl} target="_blank">
        <Styled.Button type="button">See All Scores</Styled.Button>
      </Styled.ButtonContainer>
    </>
  )
}
