import { selectWidgetType } from 'app/imports/App.selectors'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { cszUrl } from 'features/Env'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { Game, sortTeams } from '../../Games.model'
import { GameCardTeam } from '../GameCardTeam/GameCardTeam'
import * as Styled from './GameCard.styled'

dayjs.extend(customParseFormat)

export function GameCard({ game }: { game: Game }) {
  const widgetType = useSelector(selectWidgetType)

  const date = useMemo(() => {
    if (game.isTBD) return 'TBD'

    if (widgetType === WidgetTypes.School) {
      if (!game.startTime) return ''

      return dayjs(game.startTime, 'HH:mm:ss').format('h:mm A')
    }

    if (!game.eventDate) return ''

    return dayjs(game.eventDate, 'YYYY-MM-DD').format('MMM DD')
  }, [game, widgetType])

  const gameUrl = useMemo(() => {
    return `${cszUrl}/scores/view/${game.id}`
  }, [game.id])

  return (
    <Styled.Card href={gameUrl} target="_blank">
      <Styled.Date>{date}</Styled.Date>
      <Styled.SportRow>{game.sport.name}</Styled.SportRow>

      <div>
        {sortTeams(game.teams).map((team, i) => {
          return <GameCardTeam key={`${team.id}_${i}`} team={team} />
        })}
      </div>
    </Styled.Card>
  )
}
