import { FiltersCalendarContainer } from 'app/imports/App.components'
import { selectWidgetType } from 'app/imports/App.selectors'
import Logo from 'assets/img/logo_small.png'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { LayoutHeaderDistrict } from '../LayoutHeaderDistrict/LayoutHeaderDistrict'
import { LayoutHeaderLeague } from '../LayoutHeaderLeague/LayoutHeaderLeague'
import { LayoutHeaderSchool } from '../LayoutHeaderSchool/LayoutHeaderSchool'
import { LayoutHeaderState } from '../LayoutHeaderState/LayoutHeaderState'
import * as Styled from './LayoutHeader.styled'

export function LayoutHeader() {
  const widgetType = useSelector(selectWidgetType)

  return (
    <Styled.HeaderContainer>
      <Styled.Title>
        {(widgetType === WidgetTypes.State || widgetType === WidgetTypes.StateLeague) && (
          <LayoutHeaderState />
        )}
      </Styled.Title>

      <Styled.Title>{widgetType === WidgetTypes.League && <LayoutHeaderLeague />}</Styled.Title>

      <Styled.Title>{widgetType === WidgetTypes.District && <LayoutHeaderDistrict />}</Styled.Title>

      {widgetType === WidgetTypes.School && (
        <>
          <Styled.Title>
            <LayoutHeaderSchool />
          </Styled.Title>
          <FiltersCalendarContainer />
        </>
      )}

      <Styled.LabelContainer>
        <img src={Logo} alt="" />

        <Styled.Label>Presented by CSZ Network</Styled.Label>
      </Styled.LabelContainer>
    </Styled.HeaderContainer>
  )
}
