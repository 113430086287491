import { District, League, Nullish, School, Sport, State } from 'types'

export const FEATURE_NAME = 'filters'

export interface IState {
  state: Nullish<State>
  district: Nullish<District>
  sport: Nullish<Sport>
  school: Nullish<School>
  isLeague: boolean
  league: Nullish<League>
  date: string
  calendar: string[]
}

export const CALENDAR_PAGE_SIZE = 7
