import { rtkApi } from 'app/store/AppStore.rtk'
import { GamesBySchoolRequest, GamesByStateRequest, GamesResponse } from '../Games.model'

export const gamesApi = rtkApi.enhanceEndpoints({ addTagTypes: ['games'] }).injectEndpoints({
  endpoints: (build) => ({
    getGamesByState: build.query<GamesResponse, GamesByStateRequest>({
      query: (request: GamesByStateRequest) => {
        return {
          url: '/games/getForState',
          params: {
            stateId: request.stateId,
            districtId: request.districtId,
            sportId: request.sportId,
            isLeague: request.isLeague,
            leagueId: request.leagueId,
          },
        }
      },
      providesTags: ['games'],
    }),
    getGamesBySchool: build.query<GamesResponse, GamesBySchoolRequest>({
      query: (request: GamesBySchoolRequest) => {
        return {
          url: '/games/getForSchool',
          params: {
            stateId: request.stateId,
            districtId: request.districtId,
            schoolId: request.schoolId,
            date: request.date,
          },
        }
      },
      providesTags: ['games'],
    }),
  }),
})

export const { useGetGamesByStateQuery, useGetGamesBySchoolQuery } = gamesApi
