import { EffectCallback, useEffect, useState } from 'react'

export function useEffectOnce(callback: EffectCallback) {
  const [isInited, setIsInited] = useState(false)

  useEffect(() => {
    if (!isInited) {
      setIsInited(true)
      callback()
    }
  }, [callback, isInited])
}
